// Copyright (C) 2020 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Markdown } from "@ractf/ui-kit";

import Link from "components/Link";

import { hideNotification } from "../actions";


const PopupMessage = ({ data }) => {
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(hideNotification(data));
    };

    return <div onClick={onClick}>
        <div>{data.title}</div>
        <div><Markdown LinkElem={Link} source={data.body} /></div>
    </div>;
};

const AppNotifications = () => {
    const notifications = useSelector(state => state.notifications) || [];

    return <div className={"popupMessages"}>
        {notifications.map(i => <PopupMessage data={i} key={i.id} />)}
    </div>;
};
export default AppNotifications;
