// Copyright (C) 2020-2021 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { selfCheck } from "@ractf/api";
import {
    PageHead, Card, ModalSpinner
} from "@ractf/ui-kit";
import { useCategories } from "@ractf/shell-util";

import Link from "components/Link";


export default () => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState(null);

    const categories = useCategories();

    const getChallenge = (id) => {
        for (const i in categories) {
            const category = categories[i];
            for (const j in category.challenges) {
                const challenge = category.challenges[j];
                if (challenge.id === id) {
                    return challenge;
                }
            }
        }
        return null;
    };

    const loadErrors = () => {
        selfCheck().then((errors) => {
            setErrors(errors);
        });
    };

    useEffect(() => {
        loadErrors();
    }, []);

    return <>
        <PageHead title={t("admin.self_check.title")} />

        {errors ? 
            errors.length ?
                errors.map((error) => {
                    const challenge = getChallenge(error.challenge);
                    return <Card header={
                            challenge && challenge.name ? challenge.name : `[Challenge ${error.challenge}]`
                        }>
                        {error.extra ? error.extra : t("admin.self_check." + error.issue)}
                        {(challenge && challenge.category) && <Link to={
                            `/campaign/${challenge.category.id}/${error.challenge}#edit`
                            }><br/>{t("admin.self_check.edit")}</Link>
                        }
                    </Card>;
                })
            : <Card>{t("admin.self_check.all_passed")}</Card>
        : <ModalSpinner />
        }
    </>;
};
