// Copyright (C) 2021 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

import React, { useState, useCallback } from "react";

import { Container, Form, SubtleText, InputPin, Input } from "@ractf/ui-kit";


export const Login2FAPopup = ({ ...props }) => {
    const [mode, setMode] = useState(0);

    const switchMode = useCallback(() => {
        setMode(old => 1 - old);
    }, []);

    return <Container centre>
        <Form.Group>
            {mode === 0 ? (
                <InputPin digits={6} {...props} />
            ) : (
                <Input placeholder={"2-factor authentication backup code"} {...props} />
            )}
        </Form.Group>
        <SubtleText>
            <span onClick={switchMode} className={"linkStyle"}>
                Enter a {mode === 0 ? "backup" : "standard"} code instead
            </span>
        </SubtleText>
    </Container>;
};
